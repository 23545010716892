exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-classification-template-js": () => import("./../../../src/templates/classification-template.js" /* webpackChunkName: "component---src-templates-classification-template-js" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-computer-science-basic-220622-computer-science-algorithm-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/computer-science/basic/220622-computer-science-algorithm/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-computer-science-basic-220622-computer-science-algorithm-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-computer-science-basic-220622-programming-language-basic-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/computer-science/basic/220622-programming-language-basic/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-computer-science-basic-220622-programming-language-basic-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-data-science-data-collection-220419-scraping-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/data-science/data-collection/220419-scraping/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-data-science-data-collection-220419-scraping-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-data-science-data-preprocessing-220421-pandas-series-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/data-science/data-preprocessing/220421-pandas-series/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-data-science-data-preprocessing-220421-pandas-series-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-data-science-data-preprocessing-220426-pandas-dataframe-1-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/data-science/data-preprocessing/220426-pandas-dataframe-1/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-data-science-data-preprocessing-220426-pandas-dataframe-1-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-data-science-data-preprocessing-220427-pandas-dataframe-2-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/data-science/data-preprocessing/220427-pandas-dataframe-2/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-data-science-data-preprocessing-220427-pandas-dataframe-2-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-data-science-data-preprocessing-220502-pandas-dataframe-3-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/data-science/data-preprocessing/220502-pandas-dataframe-3/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-data-science-data-preprocessing-220502-pandas-dataframe-3-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-data-science-data-preprocessing-220517-pandas-dataframe-4-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/data-science/data-preprocessing/220517-pandas-dataframe-4/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-data-science-data-preprocessing-220517-pandas-dataframe-4-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-data-science-visualization-220605-seaborn-basic-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/data-science/visualization/220605-seaborn-basic/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-data-science-visualization-220605-seaborn-basic-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-data-science-visualization-230112-seaborn-advanced-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/data-science/visualization/230112-seaborn-advanced/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-data-science-visualization-230112-seaborn-advanced-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-data-science-visualization-230804-echarts-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/data-science/visualization/230804-echarts/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-data-science-visualization-230804-echarts-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-220414-development-environments-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/environment-setup/development/220414-development-environments/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-220414-development-environments-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-220416-anaconda-jupyter-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/environment-setup/development/220416-anaconda-jupyter/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-220416-anaconda-jupyter-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-220611-ide-visual-studio-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/environment-setup/development/220611-ide-visual-studio/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-220611-ide-visual-studio-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-220814-kotlin-jdk-ide-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/environment-setup/development/220814-kotlin-jdk-ide/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-220814-kotlin-jdk-ide-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-221231-jdk-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/environment-setup/development/221231-jdk/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-221231-jdk-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-230302-java-cmd-compilation-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/environment-setup/development/230302-java-cmd-compilation/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-230302-java-cmd-compilation-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-230630-ide-eclipse-setup-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/environment-setup/development/230630-ide-eclipse-setup/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-230630-ide-eclipse-setup-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-230707-python-installation-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/environment-setup/development/230707-python-installation/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-230707-python-installation-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-230821-ide-sts-setup-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/environment-setup/development/230821-ide-sts-setup/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-230821-ide-sts-setup-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-230821-tomcat-setup-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/environment-setup/development/230821-tomcat-setup/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-230821-tomcat-setup-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-230831-dbms-installation-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/environment-setup/development/230831-dbms-installation/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-230831-dbms-installation-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-231026-sql-developer-setup-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/environment-setup/development/231026-sql-developer-setup/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-environment-setup-development-231026-sql-developer-setup-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-mathematics-statistics-220605-probability-statistics-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/mathematics/statistics/220605-probability-statistics/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-mathematics-statistics-220605-probability-statistics-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-natural-language-english-220228-shame-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/natural-language/english/220228-shame/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-natural-language-english-220228-shame-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-natural-language-english-220314-guilt-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/natural-language/english/220314-guilt/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-natural-language-english-220314-guilt-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-natural-language-english-220316-embarrassment-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/natural-language/english/220316-embarrassment/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-natural-language-english-220316-embarrassment-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-natural-language-english-220319-humiliation-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/natural-language/english/220319-humiliation/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-natural-language-english-220319-humiliation-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-natural-language-english-220323-abbreviation-vs-acronym-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/natural-language/english/220323-abbreviation-vs-acronym/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-natural-language-english-220323-abbreviation-vs-acronym-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-natural-language-korean-220405-humiliation-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/natural-language/korean/220405-humiliation/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-natural-language-korean-220405-humiliation-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-natural-language-korean-220406-embarrassment-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/natural-language/korean/220406-embarrassment/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-natural-language-korean-220406-embarrassment-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-language-c-220611-c-basic-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/programming-language/c/220611-c-basic/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-language-c-220611-c-basic-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-language-c-220615-c-conditional-statements-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/programming-language/c/220615-c-conditional-statements/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-language-c-220615-c-conditional-statements-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-language-c-220616-c-iterative-statements-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/programming-language/c/220616-c-iterative-statements/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-language-c-220616-c-iterative-statements-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-programming-language-kotlin-220814-kotlin-basic-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/programming-language/kotlin/220814-kotlin-basic/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-programming-language-kotlin-220814-kotlin-basic-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-web-development-html-220407-definition-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/web-development/html/220407-definition/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-web-development-html-220407-definition-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-web-development-html-220409-tags-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/web-development/html/220409-tags/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-web-development-html-220409-tags-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-web-development-react-220410-what-is-react-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/web-development/react/220410-what-is-react/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-web-development-react-220410-what-is-react-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-web-development-react-220411-create-react-app-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/web-development/react/220411-create-react-app/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-web-development-react-220411-create-react-app-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-web-development-react-220716-jsx-elements-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/web-development/react/220716-jsx-elements/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-web-development-react-220716-jsx-elements-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-web-development-react-220720-props-components-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/web-development/react/220720-props-components/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-web-development-react-220720-props-components-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-projects-web-app-dashboard-1-covid-19-dashboard-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/projects/web-app/dashboard/1-covid19-dashboard/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-projects-web-app-dashboard-1-covid-19-dashboard-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-projects-web-app-dashboard-2-earthquake-dashboard-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/projects/web-app/dashboard/2-earthquake-dashboard/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-projects-web-app-dashboard-2-earthquake-dashboard-post-mdx" */),
  "component---src-templates-posts-template-js": () => import("./../../../src/templates/posts-template.js" /* webpackChunkName: "component---src-templates-posts-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */)
}

